import React, { useEffect, useState, useRef } from "react";
import { marked } from "marked";
import { diffWords } from "diff";
import DOMPurify from "dompurify";
import MarkdownEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import "./JDDiffChecker.css";

// Convert markdown to HTML
const renderMarkdownToHtml = (markdown) => {
  return marked(markdown);
};

// Normalize text for diffing (collapse whitespace, keep newlines)
const normalizeText = (text) => {
  return text
    .replace(/\r\n/g, "\n") // Normalize line breaks
    .replace(/\s+/g, " ") // Collapse spaces to a single space
    .trim(); // Trim leading/trailing spaces
};

// Diff text nodes at a granular level (word-level diffing)
const applyDiffToTextNodes = (
  originalNode,
  revisedNode,
  type,
  insertOrDelete
) => {
  if (originalNode.nodeType === 3 && revisedNode.nodeType === 3) {
    // Both are text nodes, normalize and split into words
    const originalText = normalizeText(
      originalNode.textContent || originalNode.nodeValue
    );
    const revisedText = normalizeText(
      revisedNode.textContent || revisedNode.nodeValue
    );

    // Use diffWords to compare word by word
    const diffResult = diffWords(originalText, revisedText);

    let diffedText = "";
    diffResult.forEach((part) => {
      if (part.added && type === "revised") {
        // Highlight additions in green for revised
        diffedText += `<span style="background-color: lightgreen">${part.value}</span>`;
      } else if (part.removed && type === "original") {
        // Highlight removals in red for original
        diffedText += `<span style="background-color: lightcoral; text-decoration: line-through;">${part.value}</span>`;
      } else if (!part.added && !part.removed) {
        // Keep unchanged content as is
        diffedText += part.value;
      }
    });

    const spanNode = document.createElement("span");
    const sanitizedText = DOMPurify.sanitize(diffedText); // Sanitize to prevent XSS
    spanNode.innerHTML = sanitizedText;

    return spanNode;
  } else if (
    (originalNode.nodeType === 1 && revisedNode.nodeType === 1) ||
    (insertOrDelete === "insert" &&
      originalNode.nodeType === 3 &&
      revisedNode.nodeType === 1) ||
    (insertOrDelete === "delete" &&
      originalNode.nodeType === 1 &&
      revisedNode.nodeType === 3)
  ) {
    // Handle element nodes recursively
    let diffedElement;
    if (originalNode.nodeType === 1) {
      diffedElement = document.createElement(originalNode.tagName);
      Array.from(originalNode.attributes).forEach((attr) => {
        diffedElement.setAttribute(attr.name, attr.value);
      });
    } else {
      diffedElement = document.createElement(revisedNode.tagName);
      Array.from(revisedNode.attributes).forEach((attr) => {
        diffedElement.setAttribute(attr.name, attr.value);
      });
    }

    let i = 0;
    while (
      i < originalNode.childNodes.length ||
      i < revisedNode.childNodes.length
    ) {
      const originalChild = originalNode.childNodes[i];
      const revisedChild = revisedNode.childNodes[i];

      if (
        originalChild?.nodeType === 3 &&
        originalChild.textContent.trim() === ""
      ) {
        i++;
        continue;
      }
      if (
        revisedChild?.nodeType === 3 &&
        revisedChild.textContent.trim() === ""
      ) {
        i++;
        continue;
      }

      let insertOrDelete = "";

      if (originalChild && revisedChild) {
        // Recursively diff child nodes
        const diffedChild = applyDiffToTextNodes(
          originalChild,
          revisedChild,
          type,
          insertOrDelete
        );
        diffedElement.appendChild(diffedChild);
      } else if (originalChild) {
        insertOrDelete = "delete";
        // Recursively handle removed nodes (only for the left side)
        if (type === "original") {
          const diffedChild = applyDiffToTextNodes(
            originalChild,
            document.createTextNode(""), // Compare with an empty text node
            "original",
            insertOrDelete
          );
          diffedElement.appendChild(diffedChild);
        }
      } else if (revisedChild) {
        insertOrDelete = "insert";
        // Recursively handle added nodes (only for the right side)
        if (type === "revised") {
          const diffedChild = applyDiffToTextNodes(
            document.createTextNode(""), // Compare with an empty text node
            revisedChild,
            "revised",
            insertOrDelete
          );
          diffedElement.appendChild(diffedChild);
        }
      }
      i++;
    }

    return diffedElement;
  }

  return originalNode.cloneNode(true);
};

// Compare HTML content and diff them
const diffHtmlContent = (originalHtml, revisedHtml) => {
  const parser = new DOMParser();
  const originalDoc = parser.parseFromString(originalHtml, "text/html");
  const revisedDoc = parser.parseFromString(revisedHtml, "text/html");

  // Diff the original HTML to show the changes
  const diffedContentOriginal = applyDiffToTextNodes(
    originalDoc.body,
    revisedDoc.body,
    "original"
  );
  const diffedContentRevised = applyDiffToTextNodes(
    originalDoc.body,
    revisedDoc.body,
    "revised"
  );

  return {
    diffedHtmlOriginal: diffedContentOriginal.innerHTML,
    diffedHtmlRevised: diffedContentRevised.innerHTML,
  };
};

// Main component to view and edit markdown diffs
const JDDiffChecker = ({
  originalJD = "",
  revisedJD = "",
  width = "100%",
  height = "500px",
  onSaveChanges,
}) => {
  const [originalMarkdown, setOriginalMarkdown] = useState(originalJD);
  const [editableMarkdown, setEditableMarkdown] = useState(revisedJD);
  const [diffHtmlOriginal, setDiffHtmlOriginal] = useState(""); // Diff for original
  const [diffHtmlRevised, setDiffHtmlRevised] = useState(""); // Diff for revised
  const [isEditing, setIsEditing] = useState(true); // Toggle between edit and diff views
  const [markdownChanges, setMarkdownChanges] = useState([]); // Track only changes
  const leftPanelRef = useRef(null); // Reference to the left panel for scroll sync
  const rightPanelRef = useRef(null); // Reference to the right panel for scroll sync

  // Update content dynamically if props change
  useEffect(() => {
    setOriginalMarkdown(originalJD);
    setEditableMarkdown(revisedJD);
    updateDiff(originalJD, revisedJD); // Highlight initial differences
  }, [originalJD, revisedJD]);

  // Function to generate highlighted differences
  const updateDiff = (markdown1, markdown2) => {
    const originalHtml = renderMarkdownToHtml(markdown1);
    const revisedHtml = renderMarkdownToHtml(markdown2);
    const { diffedHtmlOriginal, diffedHtmlRevised } = diffHtmlContent(
      originalHtml,
      revisedHtml
    );

    setDiffHtmlOriginal(diffedHtmlOriginal);
    setDiffHtmlRevised(diffedHtmlRevised);
  };

  // Track changes to the markdown (but don't store them yet)
  const handleMarkdownChange = ({ text }) => {
    const diffResult = diffWords(originalMarkdown, text); // Compare original with edited

    // Extract only added and removed text
    const changes = diffResult
      .filter((part) => part.added || part.removed)
      .map((part) => ({
        type: part.added ? "added" : "removed",
        value: part.value.trim(),
      }));

    // Track changes but don't save them until the user clicks Save
    setMarkdownChanges(changes); // Store the extracted changes
    setEditableMarkdown(text); // Update the editable markdown content
  };

  // Save Changes and update differences without resetting editableMarkdown
  const handleSave = () => {
    if (onSaveChanges) {
      onSaveChanges(editableMarkdown); // Trigger callback with the edited markdown
    }
    updateDiff(originalMarkdown, editableMarkdown); // Highlight differences
    setIsEditing(false); // Switch to diff view

    // Store the changes when saving
    console.log("Saved Changes:", markdownChanges);
  };

  const handleEdit = () => {
    setIsEditing(true); // Switch back to edit view
  };

  let isSyncingLeft = false;
  let isSyncingRight = false;

  // Synchronize scroll position with debounce
  const syncScroll = (sourceRef, targetRef, isSourceLeft) => {
    if (!sourceRef.current || !targetRef.current) return;

    const source = sourceRef.current;
    const target = targetRef.current;

    if (isSourceLeft) {
      if (isSyncingRight) return; // Prevent feedback loop
      isSyncingLeft = true;
    } else {
      if (isSyncingLeft) return; // Prevent feedback loop
      isSyncingRight = true;
    }

    // Calculate synchronized scroll position
    const scrollRatio =
      source.scrollTop / (source.scrollHeight - source.clientHeight);
    target.scrollTop =
      scrollRatio * (target.scrollHeight - target.clientHeight);

    setTimeout(() => {
      isSyncingLeft = false;
      isSyncingRight = false;
    }, 100); // Debounce timeout to reduce sensitivity
  };

  // Render markdown to HTML for the editor
  const renderHTML = (text) => {
    return renderMarkdownToHtml(text); // Convert markdown to HTML
  };

  return (
    <div className="diff-checker-container">
      <div style={{ display: "flex", height: "100%" }}>
        {/* Left Panel */}
        <div
          ref={leftPanelRef}
          onScroll={() => syncScroll(leftPanelRef, rightPanelRef, true)}
          className="diff-checker-panel"
        >
          <h3>Original Markdown (Diff)</h3>
          <div
            className="diff-checker-diff"
            dangerouslySetInnerHTML={{ __html: diffHtmlOriginal }}
          />
        </div>

        {/* Right Panel */}
        <div
          ref={rightPanelRef}
          onScroll={() => syncScroll(rightPanelRef, leftPanelRef, false)}
          className="diff-checker-panel"
        >
          {isEditing ? (
            <div>
              <h3>Edit Markdown</h3>
              <MarkdownEditor
                value={editableMarkdown}
                onChange={handleMarkdownChange} // Use the updated handler
                renderHTML={renderHTML}
                className="markdown-editor"
              />
              <button
                onClick={handleSave}
                className="diff-checker-button diff-checker-button-save"
              >
                Save Changes
              </button>
            </div>
          ) : (
            <div>
              <h3>Revised Markdown (Diff)</h3>
              <div
                className="diff-checker-diff"
                dangerouslySetInnerHTML={{ __html: diffHtmlRevised }}
              />
              <button
                onClick={handleEdit}
                className="diff-checker-button diff-checker-button-edit"
              >
                Edit Markdown
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JDDiffChecker;
