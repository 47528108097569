import React, { createContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { logoutUser, getEntitlement } from "../Api/apiService";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [entitlements, setEntitlements] = useState([]);
  const [email, setEmail] = useState(localStorage.getItem("user_email"));
  const [loadingEntitlements, setLoadingEntitlements] = useState(true); // Loading state
  const [initialLoad, setInitialLoad] = useState(true); // Add initial load state
  const [logoutDispatcher, setLogoutDispatcher] = useState(() => () => {});

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("access_token")
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refresh_token")
  );
  const navigate = useNavigate();

  const logout = useCallback(async () => {
    try {
      if (accessToken) {
        // Check if accessToken exists before calling logoutUser
        await logoutUser(accessToken, refreshToken); // use accessToken and refreshToken
      }
    } catch (error) {
      console.error("Logout error:", error);
      // Optional: Add user notification here (e.g., a toast or alert)
    }

    clearAuthState(); // Call AFTER the API call (if any)
    navigate("/");
  }, [accessToken, refreshToken, navigate]); // Add accessToken and refreshToken as dependencies

  // useEffect(() => {
  //   setLogoutDispatcher(() => logout);
  // }, [logout]);

  useEffect(() => {
    const fetchAndSetEntitlements = async () => {
      // Fetch and merge
      if (!accessToken || !initialLoad) {
        setLoadingEntitlements(false);
        return;
      }

      setLoadingEntitlements(true);

      try {
        const entitlementsData = await getEntitlement(accessToken);

        //HARDCODED for testing, change above var back once finished: entitlementsData
        // const entitlementsData = [
        //   {
        //     resources: [
        //       {
        //         entitlements: ["Jobs"],
        //         name: "Tabs",
        //       },
        //     ],
        //     role: "hr_bp",
        //     role_id: "NQRsBctwQ2FJhK5",
        //   },
        //   {
        //     resources: [
        //       {
        //         entitlements: ["Skills"],
        //         name: "Tabs",
        //       },
        //     ],
        //     role: "hr",
        //     role_id: "NQRsBctwQ2FJhK2",
        //   },
        // ];
        console.log("Entitlements Data (Raw):", entitlementsData);

        // Merge entitlements (assuming entitlementsData is an array of roles with resources)
        const mergedEntitlements = {};
        entitlementsData.forEach((role) => {
          role.resources.forEach((resource) => {
            mergedEntitlements[resource.name] =
              mergedEntitlements[resource.name] || [];
            mergedEntitlements[resource.name].push(...resource.entitlements);

            // Ensure unique entitlements (optional)
            mergedEntitlements[resource.name] = [
              ...new Set(mergedEntitlements[resource.name]),
            ];
          });
        });

        setEntitlements(mergedEntitlements); // Set merged entitlements to state
        console.log("Merged Entitlements:", mergedEntitlements);
      } catch (error) {
        console.error("Error fetching entitlements:", error);
        if (
          error.message.includes("Unauthorized") ||
          (error.response && error.response.status === 401)
        ) {
          logout(); // Call logout if unauthorized
          return; // Important: stop further execution
        }
        // Important: Handle the error properly. For example:
        setEntitlements({}); // Set to empty object or handle as needed
        // Optionally, you might want to log the user out here: logout();
        // or display an error message.
      } finally {
        setLoadingEntitlements(false);
        // Only navigate on the initial render, when accessToken is set the first time
        if (initialLoad) {
          navigate("/");
          setInitialLoad(false); // Set initialLoad to false so it doesn't navigate again
        }
      }
    };

    fetchAndSetEntitlements();
  }, [accessToken, initialLoad]);

  const clearAuthState = () => {
    setEmail(null);
    setAccessToken(null);
    setRefreshToken(null);
    localStorage.removeItem("user_email");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  };

  return (
    <AuthContext.Provider
      value={{
        email,
        setEmail,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        logout,
        entitlements,
        loadingEntitlements,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
