import React, { useContext, useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { AuthContext } from "../../State/AuthContext";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
//import { API_ENDPOINTS } from "../../config/ApiConfig";
import { getEntitlement, loginUser } from "../../Api/apiService";
import sb_logo from "../../Assets/Images/sb_logo.png";
import sb_login_bg from '../../Assets/Images/sb_login_bg.png';
import { AuthFunctionsProvider } from "../../State/AuthFunctionsContext";
import SbLoginComponent from "../../Components/SbLoginComponent/SbLoginComponent";

let CLIENT_ID =
  "953011688496-85lfc71tkfa6qhbdjavvlhf4nrtg9um3.apps.googleusercontent.com";

if (process.env.NODE_ENV === "production") {
  CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID;
}

const SbAuthPage = () => {
  const { setEmail, setAccessToken, setRefreshToken } = useContext(AuthContext);
  const navigate = useNavigate();
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  // const [error, setError] = useState([{ username: "", password: "", authfail: "" }]);

  // const sb_auth_validate_email = (email) => {
  //   // Simple email regex for validation
  //   const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   return regex.test(email);
  // };

  // const sb_auth_validate_password = (password) => {
  //   return password.length >= 3;
  // };

  // const sb_auth_handleBlur = (e) => {
  //   const { id, value } = e.target;

  //   if (id === "email") {
  //     if (!value) {
  //       setError((prevState) => ({ ...prevState, username: "Email is required." }));
  //     } else if (!sb_auth_validate_email(value)) {
  //       setError((prevState) => ({ ...prevState, username: "Please enter a valid email address." }));
  //     } else {
  //       setError((prevState) => ({ ...prevState, username: "" }));
  //     }
  //   }

  //   if (id === "password") {
  //     if (!value) {
  //       setError((prevState) => ({ ...prevState, password: "Password is required." }));
  //     } else if (!sb_auth_validate_password(value)) {
  //       setError((prevState) => ({ ...prevState, password: "Password must be at least 3 characters long." }));
  //     } else {
  //       setError((prevState) => ({ ...prevState, password: "" }));
  //     }
  //   }
  // };

  // const sb_auth_handleSubmit = async (e) => {
  //   e.preventDefault();

  //   setError(""); // Clear any previous errors

  //   // Ensure final validation before submitting
  //   if (!username || !password || error.username || error.password) {
  //     return; // Don't submit if any validation error exists
  //   }

  //   try {
  //     const data = await loginUser(username, password);

  //     console.log("Login successful:", data);

  //     if (data.access_token) {
  //       // Save the token in both localStorage and state
  //       localStorage.setItem("access_token", data.access_token);
  //       setAccessToken(data.access_token);
  //     }

  //     if (data.refresh_token) {
  //       // memory only
  //       setRefreshToken(data.refresh_token);
  //     }

  //     // Set the email in context
  //     setEmail(username);

  //     // const entitlements_data = await getEntitlement(
  //     //   localStorage.getItem("access_token")
  //     // );
  //     // console.log(entitlements_data);

  //     // // Redirect after successful login
  //     // navigate("/");
  //   } catch (err) {
  //     console.error("Login error:", err);
  //     setError((prevState) => ({ ...prevState, authfail: err.message }));// Set the error message for display
  //   }
  // };

  const sb_auth_handle_login_success = (response) => {
    try {
      const decodedResponse = jwtDecode(response.credential);
      const userEmail = decodedResponse.email;

      // Set the email and access token in context
      setEmail(userEmail);
      setAccessToken(response.credential);

      // Save token to localStorage
      localStorage.setItem("access_token", response.credential);

      navigate("/");
    } catch (error) {
      console.error("Error decoding JWT:", error);
      alert("Failed to process login. Please try again.");
    }
  };

  const sb_auth_handle_login_failure = (response) => {
    console.error("Login Failure:", response);
    alert("Google login failed. Please try again.");
  };

  return (
    <div className="container-fluid p-0">
      <div className="row vh-100 g-0">
        <div className="col-sm-6 bg-secondary d-flex align-items-center justify-content-center">
          <div className="text-center" style={{ maxWidth: '630px' }}>
            <img src={sb_login_bg} width="630" className="img-fluid mb-5" alt="Skills Bridge" />
            <div className="h2 text-dark">BETTER PEOPLE, BIGGER IMPACT</div>
            <div className="mb-2">Let's work together to shape a workforce equipped for today and tomorrow’s challenges.</div>
          </div>
        </div>
        <div className="col-sm-6 d-flex align-items-center justify-content-center">
          <div className="p-4">
            <div className="p-5"><img src={sb_logo} alt="Skills Bridge" className="img-fluid mb-4" /></div>

            <AuthFunctionsProvider>
              <SbLoginComponent />
            </AuthFunctionsProvider>

            {/* <GoogleOAuthProvider clientId={CLIENT_ID}>
              <GoogleLogin
                onSuccess={sb_auth_handle_login_success}
                onError={sb_auth_handle_login_failure}
                useOneTap={true}
              />
            </GoogleOAuthProvider> */}

            <div className="text-start p-3">
              Don't have an account? <a href="#" className="text-dark">Sign up!</a>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default SbAuthPage;
