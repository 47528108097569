import { useAuthfns } from "../../State/AuthFunctionsContext";

const SbLoginComponent = () => {
  const {
    username,
    setUsername,
    password,
    setPassword,
    error,
    sb_auth_handleSubmit,
    sb_auth_handleBlur,
    loadingAuth,
  } = useAuthfns();

  return (
    <form onSubmit={sb_auth_handleSubmit}>
      <div className="mb-3">
        <label htmlFor="email" className="form-label w-100 text-start">Employee Email</label>
        <input
          type="email"
          className="form-control"
          id="email"
          value={username}
          onBlur={sb_auth_handleBlur}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        {error.username && <p className="error-message text-danger">{error.username}</p>}
      </div>
      <div className="mb-3">
        <label htmlFor="password" className="form-label w-100 text-start">Password</label>
        <input
          type="password"
          className="form-control"
          id="password"
          value={password}
          onBlur={sb_auth_handleBlur}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {error.password && <p className="error-message text-danger">{error.password}</p>}
      </div>
      <div className="mb-3 text-end">
        <a href="#" className="text-dark">Forgot Password?</a>
      </div>

      {error.authfail && <p className="error-message text-danger">{error.authfail}</p>}

      {/* <button type="submit" className="btn btn-primary w-100 btn-lg mb-3">Sign in</button> */}

      {/* Show the loader while login is in progress */}
      {loadingAuth ? (
        <div className="d-flex justify-content-center mb-3">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <button type="submit" className="btn btn-primary w-100 btn-lg mb-3">Sign in</button>
      )}

    </form>
  );
};

export default SbLoginComponent;
