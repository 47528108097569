import React, { useRef, useState, useContext, useEffect } from "react";
import MainContent from "../../../../Components/HRTabsMainContent.jsx";
import ScrollableBox from "../../../../Components/ScrollableBox";
import JobDetail from "./JobDetails.jsx";
import PromptBox from "../../../../Components/PromptBox"; // Import PromptBox
import { jobTabContent } from "../../../../Assets/HRStaticContent.js";
import { AuthContext } from "../../../../State/AuthContext.js";
import { useSelection } from "../../../../Hooks/TabsStateContext.js";
import { useNotification } from "../../../../State/NotificationContext";
import { API_ENDPOINTS } from "../../../../config/ApiConfig";
import "./JobsTab.css";
import { uploadJD, createJD } from "../../../../Api/apiService"; // Import API functions

const JobsTab = ({ jobData }) => {
  const { triggerNotification } = useNotification();
  const { selectedJob, setSelectedJob, selectedItem, setSelectedItem } =
    useSelection();
  const fileInputRef = useRef(null);
  const { email } = useContext(AuthContext);

  const [selectedFile, setSelectedFile] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [rolesUpdated, setRolesUpdated] = useState(false);
  const [currentSbUniqueId, setCurrentSbUniqueId] = useState(null);

  const [company, setCompany] = useState(""); // State for company/business input
  const [role, setRole] = useState(""); // State for role input
  const [industry, setIndustry] = useState(""); // State for industry input
  const [businessContext, setBusinessContext] = useState(""); // State for business context input
  const [roleContext, setRoleContext] = useState(""); // State for role context input

  const [isGeneratingJD, setIsGeneratingJD] = useState(false); // Loading state for JD generation
  const [jdMethod, setJdMethod] = useState(""); // Dropdown selection state

  const token = localStorage.getItem("access_token");


  // Handle file upload
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      setSelectedFile(file);
      triggerNotification("File uploaded successfully!");

      const formData = new FormData();
      formData.append("company", company);
      formData.append("role", role);
      formData.append("industry", industry);
      formData.append("business_context", businessContext);
      formData.append("role_context", roleContext);
      formData.append("file", file);
      formData.append("email", email);
      formData.append("prompt", ""); // Use the saved prompt

      try {
        const data = await uploadJD(formData); // Call the uploadJD function
        setAnalysisResult(data);
        triggerNotification("File uploaded and analyzed successfully!");

        setRolesUpdated((prev) => !prev); // Toggle rolesUpdated to refresh roles
      } catch (error) {
        console.error("Error uploading file:", error);
        triggerNotification("Error uploading and analyzing the file.");
      }
    } else {
      alert("Please select a PDF or DOCX file.");
    }
    //   try {
    //     const response = await fetch(API_ENDPOINTS.UPLOAD, {
    //       method: "POST",
    //       body: formData,
    //     });

    //     if (!response.ok) throw new Error("Error uploading file");

    //     const data = await response.json();
    //     setAnalysisResult(data);
    //     triggerNotification("File uploaded and analyzed successfully!");

    //     setRolesUpdated((prev) => !prev); // Toggle rolesUpdated to refresh roles
    //   } catch (error) {
    //     console.error("Error uploading file:", error);
    //     triggerNotification("Error uploading and analyzing the file.");
    //   }
    // } else {
    //   alert("Please select a PDF or DOCX file.");
    // }
  };

  // Handle generating JD based on role name
  const handleGenerateJD = async () => {
    if (!role.trim()) {
      triggerNotification("Please enter a valid role name.");
      return;
    }
    console.log("roleName", role);
    setIsGeneratingJD(true); // Show loading state

    const formData = new FormData();
    formData.append("company", company);
    formData.append("role", role);
    formData.append("industry", industry);
    formData.append("business_context", businessContext);
    formData.append("role_context", roleContext);
    formData.append("email", email);
    formData.append("prompt", ""); // Use the saved prompt

    try {
      const data = await createJD(formData); // Call the createJD function
      setAnalysisResult(data);
      triggerNotification("JD generated successfully!");

      setRolesUpdated((prev) => !prev); // Refresh roles
    } catch (error) {
      console.error("Error generating JD:", error);
      triggerNotification("Error generating JD.");
    } finally {
      setIsGeneratingJD(false); // Remove loading state
    }
  };

  useEffect(() => {
    if (!selectedItem) {
      setSelectedJob(null);
    }
  }, [selectedItem, setSelectedJob]);

  useEffect(() => {
    console.log("Updated selectedJob:", selectedJob);
  }, [selectedJob]);

  if (!selectedItem || !selectedJob) {
    return (
      <div className="jobs-tab">
        <MainContent
          customText={jobTabContent}
          onJobSelect={(job) => {
            setSelectedJob(job);
            setCurrentSbUniqueId(job?.current_sb_unique_id || null); // Set the currentSbUniqueId
          }}
          email={email}
          rolesUpdated={rolesUpdated}
        />
        {selectedItem && (
          <div className="file-upload-section">
            {/* Dropdown for JD Method Selection */}
            <select
              value={jdMethod}
              onChange={(e) => setJdMethod(e.target.value)}
              className="jd-method-dropdown"
            >
              <option value="">Select JD Method</option>
              <option value="upload">Enhance JD</option>
              <option value="create">Create JD</option>
            </select>
            {/* Upload JD Section */}
            {jdMethod === "upload" && (
              <div className="upload-jd-section">
                <div className="upload-jd-section">
                  <label>
                    Enter Role Context (Optional)
                    <textarea
                      type="text"
                      placeholder="Enter Role Context (Optional)"
                      value={roleContext}
                      onChange={(e) => setRoleContext(e.target.value)}
                      className="role-input"
                    />
                  </label>
                  <label>
                    Enter Business Context (Optional)
                    <textarea
                      type="text"
                      placeholder="Enter Business Context (Optional)"
                      value={businessContext}
                      onChange={(e) => setBusinessContext(e.target.value)}
                      className="role-input"
                    />
                  </label>
                </div>
                <button
                  onClick={() => fileInputRef.current.click()}
                  className="upload-btn"
                >
                  Upload and Generate JD
                </button>
                <input
                  type="file"
                  accept=".pdf,.docx"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
            )}
            {/* Create JD Section */}
            {/* Create JD Section */}
            {jdMethod === "create" && (
              <div className="create-jd-section">
                <div className="jd-column">
                  <label>
                    Enter Company/Business
                    <input
                      type="text"
                      placeholder="Enter Company/Business"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      className="role-input"
                    />
                  </label>
                  <label>
                    Enter Role
                    <input
                      type="text"
                      placeholder="Enter Role"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      className="role-input"
                    />
                  </label>
                  <label>
                    Enter Industry
                    <input
                      type="text"
                      placeholder="Enter Industry"
                      value={industry}
                      onChange={(e) => setIndustry(e.target.value)}
                      className="role-input"
                    />
                  </label>
                </div>
                <div className="jd-column">
                  <label>
                    Enter Role Context
                    <textarea
                      placeholder="Enter Role Context"
                      value={roleContext}
                      onChange={(e) => setRoleContext(e.target.value)}
                      className="role-input"
                    />
                  </label>
                  <label>
                    Enter Business Context
                    <textarea
                      placeholder="Enter Business Context"
                      value={businessContext}
                      onChange={(e) => setBusinessContext(e.target.value)}
                      className="role-input"
                    />
                  </label>
                </div>
                <button
                  onClick={handleGenerateJD}
                  className="generate-btn"
                  disabled={isGeneratingJD}
                >
                  {isGeneratingJD ? "Generating..." : "Generate JD"}
                </button>
              </div>
            )}
            {/* Display analysis result */}
            {analysisResult && (
              <ScrollableBox
                height="300px"
                width="100%"
                content={<pre>{JSON.stringify(analysisResult, null, 2)}</pre>}
                className="scrollable-box"
              />
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="jobs-tab">
      <JobDetail
        jobId={selectedJob.job_id}
        current_sb_unique_id={currentSbUniqueId}
        parent_sb_unique_id={selectedJob.parent_sb_unique_id}
        jobName={selectedJob.name}
        email={email}
        jdFileUri={analysisResult?.file_uri}
        analyzedJDUid={analysisResult?.analyzed_jd_uid}
        setCurrentSbUniqueId={setCurrentSbUniqueId}
      />
    </div>
  );
};

export default JobsTab;
