import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Notification from "./Components/Notification";
import { AppProviders } from "./Components/AppProviders"; // Extracted Providers
import { HRRoutes } from "./Routes/HRRoutes"; // Modularized HR Routes
import { EmployeeRoutes } from "./Routes/EmployeeRoutes"; // Modularized Employee Routes
import HomePage from "./Pages/HomePage/HomePage";
import "./App.css";
import './styles.scss';
import { AuthContext } from "./State/AuthContext";

function App() {
  return (
    <Router>
      <AppProviders>
        <Notification />
        <Routes>
          <Route path="/" element={<HomePage />} />
          {HRRoutes()} {/* HR-related routes */}
          {console.log("SSO-ClientID-" + process.env.REACT_APP_SSO_CLIENT_ID)}
          {console.log("env-" + process.env.NODE_ENV)}
          {console.log("base url-" + process.env.REACT_APP_API_BASE_URL)}
          {console.log("base url-" + process.env.REACT_APP_AUTH_BASE_URL)}
          {EmployeeRoutes()} {/* Employee-related routes */}
        </Routes>
      </AppProviders>
    </Router>
  );
}

export default App;
