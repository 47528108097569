import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../State/AuthContext";
import SB_AUTH_PAGE from "../SbAuthPage/SbAuthPage";
// import TestRBAC from "../../Components/RbacTest";
import "./HomePage.css";

const SbHomePage = () => {

  const navigate = useNavigate();
  const { email, setEmail } = useContext(AuthContext);
  const [inputEmail, setInputEmail] = useState(""); // Local state for input

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    if (inputEmail.trim()) {
      // Ensure input is not empty
      setEmail(inputEmail);
    }
  };

  const handleSelectChange = (event) => {
    const selectedPath = event.target.value;
    if (selectedPath) navigate(selectedPath);
  };

  return (
    <div className="home-page">
      {!email ? (
        <SbAuthContainer
          handleLoginSubmit={handleLoginSubmit}
          inputEmail={inputEmail}
          setInputEmail={setInputEmail}
        />
      ) : (
        <SbDashboard email={email} handleSelectChange={handleSelectChange} />
      )}
    </div>
  );
};

// Extracted login container for better readability and reuse
const SbAuthContainer = ({ handleLoginSubmit, inputEmail, setInputEmail }) => (
    <SB_AUTH_PAGE />
);

// Extracted logged-in view (dashboard) for clarity
const SbDashboard = ({ email, handleSelectChange }) => (
  <>
    {/* <div>
      <TestRBAC />
    </div> */}
    <div className="dropdown-container">
      <select onChange={handleSelectChange}>
        <option value="">Select Access</option>
        <option value="/hr">HR</option>
        <option value="/employee">Employee</option>
      </select>
    </div>
    <h1>Welcome to SkillsBridge.AI! </h1>
    <p>Logged in as: {email}</p>
  </>
);

export default SbHomePage;
