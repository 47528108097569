import React, { createContext, useState, useContext } from "react";
import { AuthContext } from "../State/AuthContext";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../Api/apiService";

const AuthFunctionsContext = createContext();

export const useAuthfns = () => {
    return useContext(AuthFunctionsContext);
};

export const AuthFunctionsProvider = ({ children }) => {
    const { setEmail, setAccessToken, setRefreshToken } = useContext(AuthContext);
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState({ username: "", password: "", authfail: "" });
    const [loadingAuth, setLoadingAuth] = useState(false);

    const sb_auth_validate_email = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    const sb_auth_validate_password = (password) => {
        return password.length >= 3;
    };

    const sb_auth_handleBlur = (e) => {
        const { id, value } = e.target;

        if (id === "email") {
            if (!value) {
                setError((prevState) => ({ ...prevState, username: "Email is required." }));
            } else if (!sb_auth_validate_email(value)) {
                setError((prevState) => ({ ...prevState, username: "Please enter a valid email address." }));
            } else {
                setError((prevState) => ({ ...prevState, username: "" }));
            }
        }

        if (id === "password") {
            if (!value) {
                setError((prevState) => ({ ...prevState, password: "Password is required." }));
            } else if (!sb_auth_validate_password(value)) {
                setError((prevState) => ({ ...prevState, password: "Password must be at least 3 characters long." }));
            } else {
                setError((prevState) => ({ ...prevState, password: "" }));
            }
        }
    };

    const sb_auth_handleSubmit = async (e) => {
        e.preventDefault();
        setError(""); // Clear any previous errors

        if (!username || !password || error.username || error.password) {
            return; // Don't submit if any validation error exists
        }

        setLoadingAuth(true); // Start loading

        try {
            const data = await loginUser(username, password);

            if (data.access_token) {
                localStorage.setItem("access_token", data.access_token);
                setAccessToken(data.access_token);
            }

            if (data.refresh_token) {
                setRefreshToken(data.refresh_token);
            }

            setEmail(username);
            navigate("/");
        } catch (err) {
            setError((prevState) => ({ ...prevState, authfail: err.message }));
        } finally {
            setLoadingAuth(false); // Stop loading after the request completes
        }
    };

    return (
        <AuthFunctionsContext.Provider
            value={{
                username,
                setUsername,
                password,
                setPassword,
                error,
                setError,
                sb_auth_handleSubmit,
                sb_auth_handleBlur,
                loadingAuth,
            }}
        >
            {children}
        </AuthFunctionsContext.Provider>
    );
};
