import React, { useState } from "react";
import "./SelectRole.css";
import { API_ENDPOINTS } from "../config/ApiConfig";
import { getVersions } from "../Api/apiService";


const SelectRole = ({ placeholder, roles, email, onRoleSelect }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [versions, setVersions] = useState([]);
  const [isViewingVersions, setIsViewingVersions] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
    console.log("Search query updated:", event.target.value);
  };

  const handleRoleClick = async (role) => {
    setIsLoading(true); // Set loading state to true
    setSelectedRole(role);
    setSearchQuery(role.name);
    console.log("Selected role:", role);

    try {
      console.log("Fetching versions for role:", role.id);
      const data = await getVersions(email, role.id); // Use the axios method here

      console.log("Versions fetched successfully:", data);
      setVersions(data || []);
      setIsViewingVersions(true);
    } catch (error) {
      console.error("Error fetching versions:", error);
    } finally {
      setIsLoading(false); // Set loading state to false after API call
    }
  };


  const handleVersionClick = (version) => {
    console.log("Selected version:", version);
    onRoleSelect({
      name: version.job_title,
      job_id: version.job_id,
      current_sb_unique_id: version.sb_unique_id,
      version_count: selectedRole.version_count,
      parent_sb_unique_id: version.parent_sb_unique_id,
      version_number: version.version,
    });
  };

  const handleBackClick = () => {
    console.log("Returning to roles list");
    setIsViewingVersions(false);
    setSearchQuery("");
    setVersions([]);
  };

  const filteredRoles = roles.filter((role) =>
    role.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="select-role-container">
      <div className="search-bar">
        <input
          type="text"
          className="search-input"
          placeholder={placeholder || "Search for a role..."}
          value={searchQuery}
          onChange={handleInputChange}
        />
      </div>

      {isViewingVersions ? (
        <div className="versions-container">
          <span className="back-arrow" onClick={handleBackClick}>
            ← back
          </span>
          <ul className="version-list">
            {versions.map((version) => (
              <li
                key={version.sb_unique_id}
                className="version-item"
                onClick={() => handleVersionClick(version)}
              >
                <span className="version-name">{version.job_title}</span>
                <span
                  className={`version-id ${
                    version.error_message ? "error-version" : ""
                  }`}
                  title={version.error_message || ""}
                >
                  Version {version.version}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ) : isLoading ? ( // Show loading indicator
        <div className="loading-indicator">Loading...</div>
      ) : (
        filteredRoles.length > 0 && (
          <ul className="role-list">
            {filteredRoles.map((role) => (
              <li
                key={role.id}
                onClick={() => handleRoleClick(role)}
                className={`role-item ${
                  selectedRole?.id === role.id ? "selected" : ""
                }`}
              >
                <span className="role-name">{role.name}</span>
                <span className="version-count">{role.version_count}</span>
              </li>
            ))}
          </ul>
        )
      )}
    </div>
  );
};

export default SelectRole;
