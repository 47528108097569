import React from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import './Header.css';
import LogoutButton from "./Logout";


function Header() {
  const location = useLocation(); 

  return (
    <header className="header">
      <div className="header-background"></div>
      <div className="logo">
        <Link to="/">
          <img src="./sb_logo.png" alt="SkillsBridge.AI" />
        </Link>
      </div>

      {location.pathname === '/employee' && (
        <Link to="/employee">
          <button className="header-button" aria-label="View employee page">John D</button>
        </Link>
      )}

      {location.pathname === '/hr' && (
        <Link to="/hr">
          <button className="header-button" aria-label="View HR page">John D (HR)</button>
        </Link>
      )}
      <LogoutButton />
    </header>
  );
}

export default Header;