import React, { useState } from "react";
import PropTypes from "prop-types";
import { API_ENDPOINTS } from "../config/ApiConfig";
import "./InterviewQuestions.css"; // Import CSS for styling
import { getInterviewQuestions, saveComment } from "../Api/apiService"; // Import the functions


const InterviewQuestions = ({ jd_text, email, sb_unique_id }) => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleRoleClick = async (role) => {
    console.log(`Role clicked: ${role}`);
    setLoading(true);

    try {
      console.log("Sending request to API...");
      const data = await getInterviewQuestions(
        role,
        jd_text,
        email,
        sb_unique_id
      ); // Use the function from apiService

      if (data && data.Questions) {
        console.log("API request successful!");
        console.log("Response data:", data);
        setQuestions(data.Questions || []);
      } else {
        console.error("No questions found in response data.");
      }
    } catch (error) {
      console.error("Error while fetching questions:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCommentSave = async (questionId, comment) => {
    console.log(`Saving comment for question ID: ${questionId}`);

    try {
      const data = await saveComment(
        questionId,
        comment,
        sb_unique_id,
        email,
        "HR Screener"
      ); // Use the function from apiService

      if (data) {
        console.log("Comment saved successfully!");
        console.log("Response data:", data);
      }
    } catch (error) {
      console.error("Error while saving comment:", error);
    }
  };

  return (
    <div className="interview-questions">
      <div className="button-container">
        {["HR Screener", "Line Manager", "Senior Manager"].map((role) => (
          <button
            key={role}
            className="role-button"
            onClick={() => handleRoleClick(role)}
          >
            {role}
          </button>
        ))}
      </div>

      {loading && <p className="loading">Loading questions...</p>}

      {questions.length > 0 && (
        <div className="questions-container">
          <h3>Interview Questions</h3>
          {questions.map((question) => (
            <div key={question.id} className="question-item">
              <p>{question.text}</p>
              <textarea
                placeholder="Enter the answer"
                rows={3}
                value={question.comment || ""}
                onChange={(e) => {
                  setQuestions((prev) =>
                    prev.map((q) =>
                      q.id === question.id
                        ? { ...q, comment: e.target.value }
                        : q
                    )
                  );
                }}
              />
              {question.comment && question.comment.trim() !== "" && (
                <button
                  onClick={() =>
                    handleCommentSave(question.id, question.comment)
                  }
                >
                  Save Answer
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

InterviewQuestions.propTypes = {
  jd_text: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  sb_unique_id: PropTypes.string.isRequired,
};

export default InterviewQuestions;
