import React, { useEffect, useState, useContext } from 'react';
import './HRTabsMainContent.css';
import { useSelection } from '../Hooks/TabsStateContext';
import DropdownMenu from './DropdownMenu'; 
import SelectRole from './SelectRole'; 
import { API_ENDPOINTS } from '../config/ApiConfig';
import { AuthContext } from "../State/AuthContext.js";
import { getRoles, getProtectedData } from '../Api/apiService'; // Import the functions


function MainContent({ onJobSelect, customText, email, rolesUpdated }) {
  const { selectedItem, setSelectedItem } = useSelection();
  const [data, setData] = useState([]);
  const token = localStorage.getItem('access_token');
  const { accessToken } = useContext(AuthContext);


  const fetchRoles = async () => {
    // console.log("Check access_token authentication calling protected api");
    // try {
    //   const protectedData = await getProtectedData(accessToken);  // Use the getProtectedData function
    //   console.log("authentication calling protected api successfully:", protectedData);
    //   setData(protectedData);
    // } catch (error) {
    //   console.error('Error fetching protected data', error);
    // }

    console.log("Fetching roles with email:", email);
    try {
      const rolesData = await getRoles(email);  // Use the getRoles function
      console.log("Roles data fetched successfully:", rolesData);
      setData(rolesData);
    } catch (error) {
      console.error('Error fetching roles data', error);
    }
  };

  useEffect(() => {
    if (email) {
      console.log("Roles update detected or email provided, fetching roles...");
      fetchRoles();
    }
  }, [email, rolesUpdated]);

  const handleJobClick = (item) => {
    console.log("Role and version selected:", item);
    onJobSelect(item);
  };

  return (
    <div className="main-content">
      <div className="dropdown-section">
        <h1>{customText.title}</h1>
        <DropdownMenu 
          options={[{ value: "level", label: "Level" }, { value: "role", label: "Role" }]}
          placeholder="Select an option"
          value={selectedItem} 
          onItemSelect={(item) => setSelectedItem(item)}
        />
      </div>

      {!selectedItem && (
        <div className="content-section">
          {Object.entries(customText).map(([key, value], index) => index !== 0 && (
            Array.isArray(value) ? 
              <div key={key}><ul>{value.map((item, idx) => <p key={idx}>{item}</p>)}</ul></div> :
              <p key={key}>{value}</p>
          ))}
        </div>
      )}

      {selectedItem && (
        <SelectRole 
          placeholder="Select a role"
          roles={Array.isArray(data) ? data.map(item => ({
            id: item.job_id, 
            name: item.job_title,
            version_count: item.version_count,
          })) : []}
          email={email} 
          onRoleSelect={handleJobClick}
        />
      )}
    </div>
  );
}

export default MainContent;