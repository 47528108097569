import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { TabStateContext, useSelection } from '../Hooks/TabsStateContext';
import { AuthContext } from '../State/AuthContext';



function Navbar({ navItems, activeTab, onTabChange }) {
  const { resetJobsTabState } = useSelection();
  const { entitlements } = useContext(AuthContext); 

  //  // Extract allowed tabs from entitlements
  // const allowedTabs = entitlements["Tabs"] || []; 

  // // Filter nav items based on allowed tabs
  // const filteredNavItems = navItems.filter(item => 
  //   allowedTabs.includes(item.name.charAt(0).toUpperCase() + item.name.slice(1)) // Capitalize first letter
  // );

  const filteredNavItems = navItems

  return (
    <nav className="navbar">
      <ul>
        {filteredNavItems.map((item) => (
          <li key={item.value} className={activeTab === item.value ? 'active' : ''}>
            <Link
              to={item.route} 
              onClick={() => {
                onTabChange(item.value);
                if (item.value === 'jobs') { // Check if Jobs tab is clicked
                    resetJobsTabState();      // Reset the state
                }
              }} 
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navbar;