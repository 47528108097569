import React, { useContext } from "react";
import { AuthContext } from "../State/AuthContext";


const Logout = () => {
  const { logout } = useContext(AuthContext); // Get the logout function from context

  const handleLogout = async () => {
    await logout(); // Call the logout function from context
  };
  return <button onClick={handleLogout}>Logout</button>;
};

export default Logout;
