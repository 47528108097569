// src/api/apiService.js
import axiosInstance from "./axiosInstance";
import axios from "axios";
import { API_ENDPOINTS } from "../config/ApiConfig";

// Authentication
export const loginUser = async (username, password) => {
  try {
    const response = await axios.post(API_ENDPOINTS.AUTHENICATION, {
      username,
      password,
    });
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error to be handled by the calling component
  }
};

//api/role/entitlements

export const getEntitlement = async (accessToken) => {
  try {
    const response = await axios.get(API_ENDPOINTS.ENTITLEMENT, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error; // Rethrow the error to be handled in the calling component
  }
};

// Logout function using axios
export const logoutUser = async (accessToken, refreshToken) => {
  try {
    const response = await axios.delete(API_ENDPOINTS.LOGOUT, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "refersh-token": refreshToken,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      console.log("Logout successful");
    } else {
      throw new Error("Logout failed");
    }
  } catch (error) {
    throw error; // Rethrow the error to be handled in the calling component
  }
};

export const getRoles = async (email) => {
  try {
    const response = await axiosInstance.get(
      `${API_ENDPOINTS.ROLES}?email=${encodeURIComponent(email)}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching roles:", error);
    throw error;
  }
};

// Protected Data (Authentication)
export const getProtectedData = async (accessToken) => {
  try {
    const response = await axiosInstance.get("protected", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching protected data:", error);
    throw error;
  }
};

// Fetch Interview Questions
export const getInterviewQuestions = async (
  role,
  jd_text,
  email,
  sb_unique_id
) => {
  try {
    const response = await axiosInstance.post(
      API_ENDPOINTS.INTERVIEW_QUESTIONS,
      {
        question_owner: role,
        jd_text,
        email,
        sb_unique_id,
      }
    );
    return response.data; // Return the data from the API
  } catch (error) {
    console.error("Error fetching interview questions:", error);
    throw error; // Throw error for the component to handle
  }
};

// Save Comment for a Question
export const saveComment = async (
  questionId,
  comment,
  sb_unique_id,
  email,
  role
) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.UPDATE_COMMENT, {
      id: questionId,
      comment,
      unique_id: sb_unique_id,
      email,
      role,
    });
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error saving comment:", error);
    throw error; // Throw error for the component to handle
  }
};

// Add this new method to fetch versions in apiService.js
export const getVersions = async (email, job_id) => {
  try {
    const response = await axiosInstance.get(
      `${API_ENDPOINTS.VERSIONS}?email=${encodeURIComponent(
        email
      )}&job_id=${job_id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching versions:", error);
    throw error;
  }
};

// Fetch Error Message
export const getErrorMessage = async (jobId) => {
  try {
    const response = await axiosInstance.get(
      `${API_ENDPOINTS.ERROR_MESSAGE}?job_id=${jobId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching error message:", error);
    throw error;
  }
};

// Fetch Job Description (Original and Revised)
export const getOGJobDescription = async (jobId, email) => {
  try {
    const response = await axiosInstance.get(
      `${API_ENDPOINTS.ORIGINAL_JOB_DESCRIPTION}?job_id=${jobId}&email=${email}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching job description:", error);
    throw error;
  }
};

// Fetch Job Description (Original and Revised)
export const getSBJobDescription = async (jobId, email) => {
  try {
    const response = await axiosInstance.get(
      `${API_ENDPOINTS.SB_JOB_DESCRIPTION}?job_id=${jobId}&email=${email}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching job description:", error);
    throw error;
  }
};

// Fetch Automation Potential
export const getAutomationPotential = async (jobId, email) => {
  try {
    const response = await axiosInstance.get(
      `${API_ENDPOINTS.AUTOMATION_POTENTIAL}?job_id=${jobId}&email=${email}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching automation potential:", error);
    throw error;
  }
};

export const getCompareData = async (jobId, email) => {
  try {
    const response = await axiosInstance.get(
      `${API_ENDPOINTS.COMPARE_DATA}?job_id=${jobId}&email=${email}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching compare data:", error);
    throw error;
  }
};

export const createVersion = async (requestBody) => {
  try {
    const response = await axiosInstance.post(
      API_ENDPOINTS.CREATE_VERSION,
      requestBody,
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error generating version:", error);
    throw error;
  }
};

export const uploadJD = async (formData) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.UPLOAD, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error uploading JD:", error);
    throw error; // Throw error to handle it in the component
  }
};

export const createJD = async (formData) => {
  try {
    const response = await axiosInstance.post(
      API_ENDPOINTS.CREATE_JD,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error generating JD:", error);
    throw error; // Throw error to handle it in the component
  }
};
